export function useCompany() {
  const company = useState<Company|null>('company', () => null)

  const getCompany = async () : Promise<Company> => {
    const { $api } = useNuxtApp()

    return await $api<Company>(`/company/`)
      .then((response: Company) => company.value = response)
      .catch((error: BadRequest) => {
        throw createError({
          statusCode: 400,
          statusMessage: error.data.detail
        })
      })
  }

  return { company, getCompany }
}
