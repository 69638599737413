import { default as indexQilsBcoOqoMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/account/index.vue?macro=true";
import { default as loginMXEm8W92DTMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/account/login.vue?macro=true";
import { default as _91token_93OpwC12dTt0Meta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as completeuicr80QUdjMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset/complete.vue?macro=true";
import { default as done8u8BoBVC2uMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset/done.vue?macro=true";
import { default as indexjfQzOVoejWMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetRsYYuAAikIMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset.vue?macro=true";
import { default as passwordPyOzHQEkrmMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password.vue?macro=true";
import { default as indexYnaxn8y1mBMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/index.vue?macro=true";
import { default as _91id_93hKISjIb8MVMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/support/conversations/[id].vue?macro=true";
import { default as indexFYJ2NanZ0nMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/support/conversations/index.vue?macro=true";
import { default as indexZv6zeweLpeMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/support/index.vue?macro=true";
import { default as changeLAS31RnzUoMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/[slug]/change.vue?macro=true";
import { default as indextIr6rtb7xHMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/[slug]/index.vue?macro=true";
import { default as readvTOlZ1tj95Meta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/[slug]/read.vue?macro=true";
import { default as addjwGHiJGzjSMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/add.vue?macro=true";
import { default as index9rAzQWl83VMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/index.vue?macro=true";
import { default as indexy4TpmZvj8uMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/index.vue?macro=true";
import { default as _91id_935AfjB3DPi7Meta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/orders/[id].vue?macro=true";
import { default as indexlHQPJTicmLMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/orders/index.vue?macro=true";
import { default as performersKXSwyjjb0fMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/performers.vue?macro=true";
import { default as ticketingOT9ePOyQsSMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing.vue?macro=true";
import { default as changemJgSR4zt3dMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/users/[id]/change.vue?macro=true";
import { default as indexLkHNApRkZxMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/users/[id]/index.vue?macro=true";
import { default as set_45passwordakcEbLxwJdMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/users/[id]/set-password.vue?macro=true";
import { default as add22ewrAOyeYMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/users/add.vue?macro=true";
import { default as indexgETbdwokCMMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/users/index.vue?macro=true";
import { default as usersFd4zn3BVeCMeta } from "/home/runner/work/ticketera-admin/ticketera-admin/pages/users.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: indexQilsBcoOqoMeta || {},
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginMXEm8W92DTMeta || {},
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/account/login.vue")
  },
  {
    name: password_45resetRsYYuAAikIMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetRsYYuAAikIMeta || {},
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passwordPyOzHQEkrmMeta || {},
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/account/password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexYnaxn8y1mBMeta || {},
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/index.vue")
  },
  {
    name: "support-conversations-id",
    path: "/support/conversations/:id()",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/support/conversations/[id].vue")
  },
  {
    name: "support-conversations",
    path: "/support/conversations",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/support/conversations/index.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/support/index.vue")
  },
  {
    name: ticketingOT9ePOyQsSMeta?.name,
    path: "/ticketing",
    meta: ticketingOT9ePOyQsSMeta || {},
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing.vue"),
    children: [
  {
    name: "ticketing-events-slug-change",
    path: "events/:slug()/change",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/[slug]/change.vue")
  },
  {
    name: "ticketing-events-slug",
    path: "events/:slug()",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/[slug]/index.vue")
  },
  {
    name: "ticketing-events-slug-read",
    path: "events/:slug()/read",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/[slug]/read.vue")
  },
  {
    name: "ticketing-events-add",
    path: "events/add",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/add.vue")
  },
  {
    name: "ticketing-events",
    path: "events",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/events/index.vue")
  },
  {
    name: "ticketing",
    path: "",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/index.vue")
  },
  {
    name: "ticketing-orders-id",
    path: "orders/:id()",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/orders/[id].vue")
  },
  {
    name: "ticketing-orders",
    path: "orders",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/orders/index.vue")
  },
  {
    name: "ticketing-performers",
    path: "performers",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/ticketing/performers.vue")
  }
]
  },
  {
    name: usersFd4zn3BVeCMeta?.name,
    path: "/users",
    meta: usersFd4zn3BVeCMeta || {},
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/users.vue"),
    children: [
  {
    name: "users-id-change",
    path: ":id()/change",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/users/[id]/change.vue")
  },
  {
    name: "users-id",
    path: ":id()",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/users/[id]/index.vue")
  },
  {
    name: "users-id-set-password",
    path: ":id()/set-password",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/users/[id]/set-password.vue")
  },
  {
    name: "users-add",
    path: "add",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/users/add.vue")
  },
  {
    name: "users",
    path: "",
    component: () => import("/home/runner/work/ticketera-admin/ticketera-admin/pages/users/index.vue")
  }
]
  }
]